<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'识别列表'},{'name':'扫描结果'},{'name':'详情'}]"></layout-breadcrumb>
    </div>
    <div>
      <Card style="min-width: 700px">
        <div slot="title" style="height: 28px;line-height: 28px;">
          <Tag color="blue">
            <h2>{{ className }}</h2></Tag>
          学生:
          <Tag color="primary"><h3>{{ rowInfo.createUserName }}</h3></Tag>
          扫描结果
        </div>
        <div class="res">
          <div class="res-score">
            <div class="res-header-box">
              <div class="res-header-box-out">
                <div class="res-header-box-in">
                  <img :src="avatarUrl">
                </div>
              </div>
              <div class="res-header-box-name">{{ rowInfo.createUserName }}</div>
            </div>
            <div class="res-box-down">
              <div class="res-box-dleft">
                <div class="res-box-dleft-a">{{ rowInfo.score }}</div>
                <div class="res-box-dleft-b">综合得分</div>
              </div>
              <div class="res-box-dcenter"></div>
              <div class="res-box-dright">
                <div class="res-box-dright-a">{{ rowInfo.recognitionRate || '-' }}%</div>
                <div class="res-box-dright-b">识别率</div>
              </div>
            </div>
          </div>
          <div class="res-img">
            <div class="res-tab">
              <div class="res-tab-a" @click="changeType(1)">对比图</div>
              <div class="res-tab-a" @click="changeType(2)">原图</div>
              <div class="res-tab-c"
                   :class="{'box-transition box-moved-a':nowType==1,'box-transition box-moved-b':nowType==2}"></div>
            </div>
            <div class="res-list" :class="{'big':pageInfo.singleWordList.length > 50}" v-if="nowType==1">
              <div class="res-box" :class="{'font-active':nowFont.index==item.index}" @click="changeSelectFont(item)"
                   v-for="(item,index) in pageInfo.singleWordList"
                   :key="index">
                <div class="res-box-img">
                  <img :src="item.imgOverlapping" v-if="item.textScore>0">
                  <img :src="item.imgHandwriting" v-else-if="item.textScore==0">
                </div>
                <div class="res-box-score1" v-if="item.textScore==0">×</div>
                <div class="res-box-score2" v-else-if="item.textScore>0">{{ item.textScore }}</div>
              </div>
              <div style="margin: 10px 0">
                <div>
                  <span style="color: red">*</span>
                  点击单字即可查看该单字点评
                </div>
              </div>
            </div>
            <div class="ori-box" v-if="nowType==2">
              <div class="ori-box-img">
                <img :src="rowInfo.originalImg">
              </div>
            </div>
          </div>
          <div class="result-dtype1-box-link">
            <div class="result-dtype1-box-link-a">
              <img :src="linkUrl">
            </div>
            <div class="result-dtype1-box-link-b">
              <img :src="linkUrl">
            </div>
          </div>
          <div class="single-list">
            <div class="single-title">
              <div class="single-title-img">
                <img :src="bkUrl">
              </div>
              <div class="single-title-label">单字点评</div>
            </div>
            <div class="single-box">
              <div class="single-box-img">
                <img :src="nowFont.imgHandwriting">
              </div>
              <div class="single-box-img" :class="{'single-box-img-default':!nowFont.imgOverlapping}">
                <img :src="nowFont.imgTemplate">
              </div>
              <div class="single-box-img">
                <img :src="nowFont.imgOverlapping">
              </div>
              <div class="single-box-score">
                <div class="single-box-score-label">单字得分</div>
                <div class="single-box-score-value">{{ nowFont.textScore }}</div>
              </div>
            </div>
            <div class="single-analyse" v-if="nowFont.imgOverlapping">
              <div class="single-analyse-li">
                <div class="single-analyse-title">
                  <div>居中：<span class="single-analyse-score">{{ nowFont.textMiddle }}</span>分</div>
                </div>
                <div class="single-analyse-desc">
                  {{ nowFont.centered }}
                </div>
              </div>
              <div class="single-analyse-li">
                <div class="single-analyse-title">
                  <div>占格：<span class="single-analyse-score">{{ nowFont.textShape }}</span>分</div>
                </div>
                <div class="single-analyse-desc">
                  {{ nowFont.occupyGrid }}
                </div>
              </div>
              <div class="single-analyse-li">
                <div class="single-analyse-title">
                  <div>笔画：<span class="single-analyse-score">{{ nowFont.textStrokes }}</span>分</div>
                </div>
                <div class="single-analyse-desc">
                  {{ nowFont.strokes }}
                </div>
              </div>
              <div class="single-analyse-li">
                <div class="single-analyse-title">
                  <div>结构：<span class="single-analyse-score">{{ nowFont.textStructure }}</span>分</div>
                </div>
                <div class="single-analyse-desc">
                  {{ nowFont.structure }}
                </div>
              </div>
            </div>
            <div class="single-analyse-error" v-else>
              <div>AI机器人未识别，请检查书写是否正确，字迹是否清晰，扫描是否清楚！</div>
            </div>
          </div>
          <div class="result-dtype1-box-link">
            <div class="result-dtype1-box-link-a">
              <img :src="linkUrl">
            </div>
            <div class="result-dtype1-box-link-b">
              <img :src="linkUrl">
            </div>
          </div>
          <div class="sum-list">
            <div class="sum-title">
              <div class="sum-title-img">
                <img :src="bkUrl">
              </div>
              <div class="sum-title-label">综合评价</div>
            </div>
            <div class="sum-ul">
              <div class="sum-li" v-for="(item,index) in pageInfo.comprehensiveEvaluationList" :key="index">
                <div class="sum-li-index">{{ index + 1 }}</div>
                <div class="sum-li-desc">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail-single",
  data() {
    return {
      sysNo: '',
      rowInfo: '',
      pageInfo: '',
      nowFont: '',
      className: '',
      avatarUrl: require('../../../../assets/images/pushwork/user.png'),
      nowType: 1,
      bkUrl: require('../../../../assets/images/pushwork/bg_title.png'),
      linkUrl: require('../../../../assets/images/pushwork/bg_4.png')
    }
  },
  created() {
    let query = this.$routeQuery() || false
    if (query) {
      this.rowInfo = query.rowInfo
      this.sysNo = query.sysNo
      this.className = query.className
      this.queryPageInfo();
    }
  },
  mounted() {
  },
  methods: {
    queryPageInfo() {
      this.$http.queryWordRecognitionById(this.sysNo).then(res => {
        if (res.status == 1) {
          this.pageInfo = res.data;
          for (let item of this.pageInfo.singleWordList) {
            if (item.textScore >= 0) {
              this.nowFont = item;
              break;
            }
          }
        }
      });
    },
    changeSelectFont(item) {
      if (item.textScore < 0) {
        return;
      }
      this.nowFont = item;
    },
    changeType(type) {
      this.nowType = type;
    }
  }
}
</script>

<style scoped lang="less">
.res {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.res-score {
  width: 560px;
  height: 256px;
  background-color: #ffffff;
  border-radius: 30px;
  border: 14px solid #ffb023;
  box-shadow: inset 0 0 20px #ffb023;
  margin-top: 45px;

  .res-header-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;

    .res-header-box-out {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background-color: white;
      box-shadow: 0 -3px 5px #ffb023;

      .res-header-box-in {
        width: 105px;
        height: 105px;
        border-radius: 50.5px;
        overflow: hidden;
      }
    }

    .res-header-box-name {
      margin-top: 5px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #414A5D;
    }
  }

  .res-box-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -8px;

    .res-box-dleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 49%;

      .res-box-dleft-a {
        font-size: 60px;
        font-weight: bold;
        color: #ff3d3d;
      }

      .res-box-dleft-b {
        font-size: 14px;
        color: #677082;
      }
    }

    .res-box-dcenter {
      width: 1px;
      height: 25px;
      background-color: #c1c5cb;
      margin-top: 18px;
    }

    .res-box-dright {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 49%;

      .res-box-dright-a {
        font-size: 60px;
        font-weight: bold;
        color: #fecd36;
      }

      .res-box-dright-b {
        font-size: 14px;
        color: #677082;
      }
    }
  }
}

.res-img {
  display: flex;
  flex-direction: column;
  align-items: center;

  .res-tab {
    width: 250px;
    height: 40px;
    background-color: #fcc082;
    border-radius: 20px;
    display: flex;
    margin: 20px 0;
    position: relative;

    .res-tab-a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      width: 50%;
      z-index: 999;
    }

    .res-tab-c {
      position: absolute;
      left: 0;
      width: 50%;
      height: 100%;
      border-radius: 20px;
      background-image: linear-gradient(#fecd36, #ff9036);
    }
  }

  .res-list {
    width: 550px;
    box-shadow: 0 0 4px #999;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    &.big{
      width: 770px;
    }

    .res-box {
      width: 100px;
      height: 100px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      margin: 4px;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      &:hover {
        border: 1px solid #c62922;
      }

      .res-box-img {
        width: 97px;
        height: 97px;
      }

      .res-box-score1 {
        position: absolute;
        top: -8px;
        right: 2px;
        color: black;
        font-weight: bold;
        font-size: 24px;
      }

      .res-box-score2 {
        position: absolute;
        top: -3px;
        right: 2px;
        font-size: 20px;
        color: #FF3D3D;
        width: 32px;
        text-align: right;
      }
    }
  }

  .ori-box {
    width: 550px;
    box-shadow: 0 0 4px #999;
    border-radius: 6px;
    overflow: hidden;
    padding: 5px;

    .ori-box-img {
      width: 100%;
    }
  }
}

.box-transition {
  transition: all 0.5s;
}

.box-moved-a {
  left: 0 !important;
}

.box-moved-b {
  left: 125.5px !important;
}

.single-list {
  width: 550px;
  box-shadow: 0 0 4px #999;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 5px;

  .single-title {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 16px;

    .single-title-img {
      width: 265px;
    }

    .single-title-label {
      color: #fd6920;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      position: absolute;
      top: -5px;
    }
  }

  .single-box {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    .single-box-img {
      width: 100px;
      height: 100px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      overflow: hidden;

      &:hover {
        border: 1px solid #c62922;
      }
    }

    .single-box-img-default {
      background-color: black;
    }

    .single-box-score {
      width: 100px;
      height: 100px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 1px solid #c62922;
      }

      .single-box-score-label {
        color: #888888;
        margin-top: 20px;
      }

      .single-box-score-value {
        color: #FF3D3D;
        font-weight: bold;
        font-size: 60px;
        margin-top: -10px;
      }
    }
  }

  .single-analyse {
    .single-analyse-li {
      min-height: 120px;

      .single-analyse-title {
        width: 105px;
        height: 30px;
        background-image: linear-gradient(#ffba26, #fe8d13);
        color: #ffffff;
        border-radius: 0 30px 30px 0;
        font-size: 14px;
        margin: 20px 0 0 -5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .single-analyse-score {
          font-size: 20px;
        }
      }

      .single-analyse-desc {
        margin: 20px 10px 20px 10px;
      }
    }
  }

  .single-analyse-error {
    background-color: #ffeddf;
    border-radius: 10px;
    padding: 10px;
    margin: 15px 15px 40px 15px;
  }
}

.sum-list {
  width: 550px;
  box-shadow: 0 0 4px #999;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  .sum-title {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 16px;

    .sum-title-img {
      width: 265px;
    }

    .sum-title-label {
      color: #fd6920;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      position: absolute;
      top: -5px;
    }
  }

  .sum-ul {
    display: flex;
    flex-direction: column;

    .sum-li {
      display: flex;
      margin: 20px 0;

      .sum-li-index {
        min-width: 20px;
        height: 20px;
        border-radius: 10px;
        background-image: linear-gradient(#ffdba9, #fea11b);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      .sum-li-desc {

      }
    }

  }
}

.result-dtype1-box-link {
  display: flex;
  justify-content: space-between;
  margin: -12px 0;
  width: 180px;

  .result-dtype1-box-link-a {
    width: 14px;
    height: 46px;
  }

  .result-dtype1-box-link-b {
    width: 14px;
    height: 46px;
  }
}

.font-active {
  border: 1px solid #c62922 !important;
}
</style>